




















































































import { Component, Vue } from 'vue-property-decorator'
import { apiGoodsDeliveryDetail } from '@/api/finance/finance'
import downloadExcel from 'vue-json-excel'
import { funDate } from '@/utils/util'

@Component({
    components: {
        downloadExcel,
    }
})
export default class FinanceProfile extends Vue {
    /** S Data **/
    ProfileData = {}
    timeData: any = []
    // 顶部查询表单
    form = {
        start_time: '',
        end_time: ''
    }
    loading = false
    exportData: any = [ ] // 导出的表格数据
    tableData: any = [] // 显示的表格数据
    // 头部文字
    header_text:any = ''
    json_fields = {
        "配送产品": "goods_name",    //常规字段
        "数量（盒）":"num",
        "应销售金额（元）":"total_price",
        "实收金额（扣除优惠）元":"total_pay_price",
        "成本金额（元）":"total_cost_price",
        "配送费（元）":"deliver_fee",
        "佣金（元）":"earnings",
        "出库单价（元）": "outgoing_unit_price",
        "出库总价（元）": "total_delivery_price"
    }

    pickerOptions = {
          shortcuts: [{
            text: '最近一周',
            onClick(picker: any) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近一个月',
            onClick(picker: any) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit('pick', [start, end]);
            }
          }, {
            text: '最近三个月',
            onClick(picker: any) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
              picker.$emit('pick', [start, end]);
            }
          }]
    }
    /** E Data **/


    /** S Methods **/
    
    // 获取数据
    getData() {
        this.getExportData()
    }
    // 获取导出数据
    getExportData() {
        this.tableData = []
        this.exportData = []
        this.loading = true
        apiGoodsDeliveryDetail({
            ...this.form
        }).then(res => {
            this.loading = false
            console.log("res", res)
            this.exportData = [...res]
            this.tableData = [...res]
            console.log("this.tableData", this.tableData)
            let totle_obj:any = {
                goods_name: '合计',
                num: 0,
                total_price: 0,
                total_pay_price: 0,
                total_cost_price: 0,
                deliver_fee: 0,
                earnings: 0,
                outgoing_unit_price: 0,
                total_delivery_price:0
            }
            res.forEach((item:any) => {
                totle_obj.num+= Number(item.num)
                totle_obj.total_price+= Number(item.total_price)
                totle_obj.total_pay_price+= Number(item.total_pay_price)
                totle_obj.total_cost_price+= Number(item.total_cost_price)
                totle_obj.deliver_fee+= Number(item.deliver_fee)
                totle_obj.earnings+= Number(item.earnings)
                totle_obj.outgoing_unit_price+= Number(item.outgoing_unit_price)
                totle_obj.total_delivery_price+= Number(item.total_delivery_price)

                console.log("item", item)
            })

            totle_obj.total_price.toFixed(2);
            totle_obj.total_pay_price.toFixed(2);
            totle_obj.total_cost_price.toFixed(2);
            totle_obj.deliver_fee.toFixed(2);
            totle_obj.earnings.toFixed(2);
            totle_obj.outgoing_unit_price.toFixed(2);
            totle_obj.total_delivery_price.toFixed(2);
            this.exportData.push({ ...totle_obj})
            console.log("this.exportData", this.exportData)
        })
    }
    // 时间分割
    splitTime() {
        if (this.timeData != null) {
            this.form.start_time = this.timeData[0]
            this.form.end_time = this.timeData[1]
        }
    }
    // 重置
    onReset() {
        let start_time: any = funDate(0);
        let end_time: any = funDate(0)
        this.timeData = [start_time, end_time]
        this.form.start_time = start_time
        this.form.end_time = end_time
        this.getExportData()
    }
    /** E Methods **/

    /** S Life Cycle **/
    created() {
        let start_time: any = funDate(0);
        let end_time: any = funDate(0)
        this.timeData = [start_time, end_time]
        this.form.start_time = start_time
        this.form.end_time = end_time
        this.getExportData()
    }
    /** E Life Cycle **/
}
